<template>
    <div v-if="data">
        <component :is="`style`">
            {{data.css}}
        </component>
        <div v-html="data.html">

        </div>
    </div>
</template>
    
  <script>
  import { reqGetPage } from '@/api/admin/page'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  export default {
    name: 'WebBuilder',
    data() {
      return {
        data: {}
      }
    },
    mounted() {
        this.getPage()
    },
    methods: {
      getPage() {
        reqGetPage(this.$route.params.id).then(response => {
          this.data = response.data.data
        })
      },
      showToast(variant, icon, title, message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text: message,
            variant,
          },
        })
      },
    }
  }
  </script>