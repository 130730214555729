import axios from '@/libs/axios'

export function reqGetPages(payload) {
  return axios.get('api/admin/pages', { params: payload })
}
export function reqGetPage(id) {
  return axios.get(`api/admin/pages/${id}`)
}
export function reqCreatePage(payload) {
  return axios.post('api/admin/pages', payload)
}
export function reqUpdatePageBuilder(id, payload) {
  return axios.post('api/admin/pages/'+id+'/builder', payload)
}
export function reqUpdatePage(id, payload) {
  return axios.post(`api/admin/pages/${id}?_method=PATCH`, payload)
}
export function reqDeletePage(id) {
  return axios.delete(`api/admin/pages/${id}`)
}
